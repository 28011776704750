header {
  width: 100%;
  border-bottom: 2px solid var(--accent_opacity_20);
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: var(--background);
  h4 {
    a {
      color: inherit;
      text-decoration: none;
    }
  }

  .nav-container-home {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    padding: var(--space-xs) 0;
  }
  #theme-toggle {
    justify-self: end;
    background-color: var(--button_background_one);
    color: var(--text_primary);
    width: 3rem;
    height: 3rem;
    font-size: var(--step--1);
    border-radius: 50%;
    transition: all 0.3s ease-in-out;

    &:hover {
      transform: rotate(-2deg) scale(1.05);
    }
  }
}
