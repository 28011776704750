@use "foundations";
@use "header";
@use "footer";
@use "home";
@use "process-log";

html {
  font-family: system-ui, sans-serif; // Fallback font
}

html.fonts-loaded {
  font-family: "IBM Plex Mono", monospace;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: var(--background);
  color: var(--text_primary);
  font-family: "IBM Plex Mono", monospace;
  font-size: var(--step-0);
  transition: background-color 0.2s ease, color 0.2s ease;
  overflow: hidden;
  height: 100svh;
  display: flex;
  flex-direction: column;
  font-weight: 400;
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: "Young Serif", serif;
    margin: 0;
    font-weight: 400;
  }
  p {
    margin: 0;
    font-weight: 400;
  }
  h1 {
    font-size: var(--step-4);
  }
  h2 {
    font-size: var(--step-3);
  }
  h3 {
    font-size: var(--step-2);
  }
  h4 {
    font-size: var(--step-1);
  }
  h5 {
    font-size: var(--step-0);
  }
}

.all-content {
  display: grid;
  grid-template-rows: auto 1fr auto;
  min-height: 100vh;
  overflow-y: auto;
}

.centered-container {
  box-sizing: content-box;
  margin-inline: auto;
  width: calc(100% - (var(--space-l) * 2));
  max-width: 800px;
  padding-inline: var(--space-l);
}

// work styles

.work-container {
  display: flex;
  flex-direction: column;
  gap: var(--space-l);
  align-items: center;

  h1 {
    width: 100%;
  }
  a {
    width: fit-content;
    display: block;
  }
}

.work-block {
  width: 100%;
  background-color: var(--button_background_one);
  padding: 4px;
  border-radius: 12px;
  position: relative;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--background_thin_veil);
    animation: pulse 1.5s infinite;
  }

  &.loaded::before {
    display: none;
  }

  .work-img {
    width: 100%;
    border-radius: 8px;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }

  &.loaded .work-img {
    opacity: 1;
  }
}

@keyframes pulse {
  0% {
    opacity: 0.6;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0.6;
  }
}

// about styles

.photo-frame {
  background-color: var(--background_thin_veil);
  padding: var(--space-l);
  border-radius: 12px;
  width: fit-content;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--space-m);
}

.photo-caption {
  font-size: var(--step--1);
  text-align: center;
  max-width: 280px;
}

.one-column-content {
  display: flex;
  flex-direction: column;
  gap: var(--space-xl);
  align-items: center;
}

.no-break {
  white-space: nowrap;
}

// general styles
.main-content {
  padding: var(--space-l) 0;
  display: flex;
  flex-direction: column;
  gap: var(--space-l);
}

.content-block {
  display: flex;
  flex-direction: column;
  width: 540px;
  gap: var(--space-m);
  h2,
  h3,
  h4,
  h5 {
    rotate: -2deg;
  }

  a {
    color: var(--accent);
    text-decoration: none;
    transition: all 0.2s ease-in-out;
    display: inline-block;
    font-weight: 600;
    &:hover {
      transform: rotate(-1deg) scale(1.05);
    }
  }
}

@media screen and (max-width: 620px) {
  .content-block {
    width: 100%;
  }
}
