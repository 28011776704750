.home-content {
  padding: var(--space-xl) 0;
}

.intro-blocks {
  display: flex;
  flex-direction: column;
  gap: var(--space-xl);
  h1,
  h2,
  h3,
  h4,
  h5 {
    rotate: -2deg;
  }
}

.intro-block {
  display: flex;
  flex-direction: column;
  gap: var(--space-l);
  p {
    font-size: var(--step-1);
  }
  p > a {
    color: var(--accent);
    text-decoration: none;
    transition: all 0.2s ease-in-out;
    display: inline-block;
    font-weight: 600;
    &:hover {
      transform: rotate(-4deg) scale(1.05);
    }
  }
}

.home-options {
  display: flex;
  flex-direction: column;
  gap: var(--space-l);
}

.home-option-link {
  text-decoration: none;
  color: inherit;

  &:hover .home-option {
    transform: rotate(-1deg) scale(1.05);
  }
}

.home-option {
  background-color: var(--callout_block);
  padding: var(--space-s) var(--space-m);
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.2s ease-in-out;
}
