// post list
.content-block {
  .post-list {
    display: flex;
    flex-direction: column;
    gap: var(--space-l);
  }

  .post-link {
    text-decoration: none;
    color: var(--text_primary);
    width: 100%;

    &:hover .post-preview {
      transform: rotate(-1deg) scale(1.01);
    }
  }

  .post-preview {
    background-color: var(--background_thin_veil);
    padding: var(--space-s) var(--space-m);
    border-radius: 12px;
    transition: transform 0.2s ease-in-out;
    display: flex;
    flex-direction: column;
    gap: var(--space-xs);
    a {
      color: var(--text_primary);
    }

    .post-title {
      rotate: 0deg;
    }

    .post-date {
      font-size: var(--step--1);
      color: var(--text_secondary);
    }

    .post-description {
      font-size: var(--step-0);
    }
  }
}

// post content

.post-date {
  color: var(--text_secondary);
  font-size: var(--step--1);
}

.work-container .content-block .post-content {
  h1,
  h2,
  h3,
  h4,
  h5 {
    rotate: 0deg;
  }
  h2 {
    margin: var(--space-m) 0;
  }
  h3,
  h4,
  h5 {
    margin: var(--space-s) 0;
  }
  p {
    margin-bottom: var(--space-s);
  }
  img {
    width: 100%;
    border-radius: 12px;
  }
}
